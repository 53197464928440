<script lang="ts" setup>

</script>

<template>
  <div>
    <slot />
  </div>
  <ClientOnly>
    <Toast />
  </ClientOnly>
</template>

<style lang="scss"></style>